import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import Routes from './Routes';
import { setSafeArea } from './hooks';
import './theme/global.css';
import './theme/variables.css';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';

setupIonicReact({
  swipeBackEnabled: false,
  hardwareBackButton: false,
  mode: 'ios',
});

const App: React.FC = () => {
  useEffect(() => {
    (async () => {
      /*HyperDX.init({
        apiKey: process.env.REACT_APP_HYPERDX_API_KEY!,
        service: process.env.REACT_APP_HYPERDX_SERVICE_NAME || 'Tedispo',
        tracePropagationTargets: [/staging.tedispo.com/i], // Set to link traces from frontend to backend requests
        consoleCapture: true, // Capture console logs (default false)
        advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
      });*/
      // log rocket only from europe
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

      // this handle redirection
      if (!isPlatform('hybrid')) {
        console.log('this is not a hybrid app, not running cordova nor capacitor...');
        if (isPlatform('ios')) {
          console.log('this is from ios device');
          window.location.href = 'itms://itunes.apple.com/fr/app/tedispo/id1608791944';
        }
        if (isPlatform('android')) {
          console.log('this is from android device');
          const link = document.createElement('a');
          link.href = 'https://play.google.com/store/apps/details?id=com.tedispo';
          link.target = '_self';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        if (isPlatform('android')) {
          LogRocket.init('f34iwc/tedispo');
        } else {
          if (Intl.DateTimeFormat().resolvedOptions().timeZone.includes('Europe') && isPlatform('ios')) {
            // logrocket for all ios
            LogRocket.init('f34iwc/tedispo');
          }
        }
        const { enabled } = await FirebaseCrashlytics.isEnabled();
        console.log('crashltics enabled?', enabled);
      }
      await setSafeArea();
      //await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
    })();
  }, []);
  return (
    <IonApp>
      <Routes />
    </IonApp>
  );
};

export default App;
